import React, { useState, useEffect, useReducer, useContext } from 'react'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ResponsiveAppBar from './ResponsiveAppBar';
import PublicMenu from './PublicMenu';

const Grid = () => {
  const [_, forceUpdate] = useReducer(x => x + 1, 0);

  const [pilotos, setPilotos] = useState([]);

  const [piloto, setPiloto] = useState(Array(15));
  const [resultado, setResultado] = useState(Array(15));

  var gridUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto/_id_/grid";
  var pilotosUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto";

  useEffect(() => {

      const fetchPilotos = async () => {
        await fetch(`${pilotosUrl}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
              if (response.ok) {
              response.json().then((json) => {
                  setPilotos(json)
              });
            }
          }).catch((error) => {
            console.log(error);
          });
      };

    fetchPilotos();
  }, [pilotosUrl])

  const fetchGrid = async (pos, id) => {
    await fetch(`${gridUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setResultado(replace(resultado, pos, json));
            forceUpdate();
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const listResultados = async(pos, id) => {
    if (!id) { setResultado(replace(resultado, pos, null)); return; }

    console.log(id);

    fetchGrid(pos, id);    
  };

  const replace = (array, pos, newValue) => {
    array.splice(pos, 1, newValue);
    return array;
  }

  const grid =  Array.from({length: 15}, (v, i) => i).map(i => 
    <tr key={i}>
      <td>
      <Autocomplete
          value={piloto[i]}
          onChange={(event, newValue) => { listResultados(i, newValue?.id); setPiloto(replace(piloto, i, newValue)); }}
          options={pilotos}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Piloto" />}
        />
      </td>
      <td>{ resultado[i] ? resultado[i].dr : '' }</td>
      <td>{ resultado[i] ? resultado[i].grid : '' }</td>
    </tr>

  );

  return (
    <div>
      <PublicMenu/>

      <table>
        <thead>
        <tr>
          <th scope="col">Piloto</th>
          <th scope="col">DR</th>
          <th scope="col">Grid esperado</th>
        </tr>
        </thead>
        <tbody>
        {grid}
        </tbody>
      </table>
    </div>
  )
}

export default Grid