import React, { useState, useEffect, useContext } from 'react'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ResponsiveAppBar from './ResponsiveAppBar';
import PublicMenu from './PublicMenu';

const ResultadoCampeonato = () => {
  const [ligas, setLigas] = useState([]);
  const [campeonatos, setCampeonatos] = useState([]);
  const [edicoes, setEdicoes] = useState([]);
  const [grids, setGrids] = useState([]);
  const [tabela, setTabela] = useState([]);

  const [liga, setLiga] = useState();
  const [campeonato, setCampeonato] = useState();
  const [edicao, setEdicao] = useState();
  const [grid, setGrid] = useState();

  var ligasUrl = "https://rankingav.us-3.evennode.com" + "/api/liga";
  var campeonatosUrl = "https://rankingav.us-3.evennode.com" + "/api/liga/_id_/campeonatos";
  var edicoesUrl = "https://rankingav.us-3.evennode.com" + "/api/campeonato/_id_/edicoes";
  var gridsUrl = "https://rankingav.us-3.evennode.com" + "/api/edicao/_id_/grids";
  var tabelaUrl = "https://rankingav.us-3.evennode.com" + "/api/edicao/_id_/resultados?grid=_grid_";

  useEffect(() => {

      const fetchLigas = async () => {
        await fetch(`${ligasUrl}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
              if (response.ok) {
              response.json().then((json) => {
                  setLigas(json)
              });
            }
          }).catch((error) => {
            console.log(error);
          });
      };

    fetchLigas();
  }, [ligasUrl])

  const fetchCampeonatos = async (id) => {
    await fetch(`${campeonatosUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setCampeonatos(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const fetchEdicoes = async (id) => {
    await fetch(`${edicoesUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setEdicoes(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const fetchGrids = async (id) => {
    await fetch(`${gridsUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setGrids(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };
  const fetchTabela = async (id, grid) => {
    await fetch(`${tabelaUrl.replace('_id_', id).replace('_grid_', grid)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setTabela(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const listCampeonatos = async(liga) => {
    setCampeonatos([]);
    setEdicoes([]);
    setGrids([])
    setTabela([]); 

    if (!liga) { return; }
    
    setLiga(liga);
    fetchCampeonatos(liga.id);
  };

  const listEdicoes = async(campeonato) => {
    setEdicoes([]);
    setGrids([])
    setTabela([]); 

    if (!campeonato) { return; }
    
    setCampeonato(campeonato)
    fetchEdicoes(campeonato.id);
  };

  const listGrids = async(edicao) => {
    setGrids([])
    setTabela([]); 

    if (!edicao) { return; }
    
    setEdicao(edicao);
    fetchGrids(edicao.id);
  };

  const getLabel = (resultado) => {
    if (!resultado) { return; }

    const label = resultado?.apelidos ? resultado.apelidos[resultado.apelidos.length - 1] : label;
    const apelidos = resultado?.apelidos ? ( resultado.apelidos.length > 1 ? ' (' + resultado.apelidos + ')' : '') : '';

    return label + apelidos;
  }

  const resultados = tabela.map(r =>
    <li>
        <p>{ getLabel(r) }</p>
        <p>Posicão final: {r.posicao}</p>
    </li>
  );

  return (
    <div>
      <PublicMenu/>

      <div>
        <Autocomplete
          value={liga}
          onChange={(event, newValue) => { listCampeonatos(newValue);  }}
          options={ligas}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Liga" />}
        />
      </div>
      <div>
        <Autocomplete
          value={campeonato}
          onChange={(event, newValue) => { listEdicoes(newValue);  }}
          options={campeonatos}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Campeonato" />}
        />
      </div>
      <div>
        <Autocomplete
          value={edicao}
          onChange={(event, newValue) => { listGrids(newValue); }}
          options={edicoes}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Edição" />}
        />
      </div>
      <div>
        <Autocomplete
          value={grid}
          onChange={(event, newValue) => { setGrid(newValue); fetchTabela(edicao?.id, newValue?.id); }}
          options={grids}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Grid" />}
        />
      </div>
      <div>
        { <ul>{resultados}</ul> }
      </div>
    </div>
  )
}

export default ResultadoCampeonato