// App.js
import LineChart from "./components/LineChart";
import Grafico from "./components/Grafico";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
function App() {
  return (
    <div>
      <Grafico />
    </div>
  );
}

export default App;