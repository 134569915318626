import React, { useState, useEffect, useContext } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ResponsiveAppBar from './ResponsiveAppBar';
import PublicMenu from './PublicMenu';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const Grafico = () => {
  const [historico, setHistorico] = useState([])
  const [regulamento, setRegulamento] = useState([])
  const [elo, setElo] = useState([])
  const [scale, setScale] = useState([])

  const [pilotos, setPilotos] = useState([]);
  const [piloto, setPiloto] = useState(null);

  var historicoUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto/_id_/grafico/historico";
  var regulamentoUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto/_id_/grafico/regulamento";
  var eloUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto/_id_/grafico/elo";
  var scaleUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto/_id_/grafico/scale";

  var pilotosUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto";

  useEffect(() => {

      const fetchPilotos = async () => {
        await fetch(`${pilotosUrl}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
              if (response.ok) {
              response.json().then((json) => {
                  setPilotos(json)
              });
            }
          }).catch((error) => {
            console.log(error);
          });
      };

    fetchPilotos();
  }, [pilotosUrl])

  console.log("historico", historico);
  console.log("regulamento", regulamento);
  console.log("elo", elo);
  console.log("scale", scale);
  console.log("pilotos", pilotos);

  var data = {
    labels: historico?.map(c => c.x),
    datasets: [{
      label: 'Participações Oficiais',
      data: historico?.map(c => c.y),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    },
    {
        label: 'Esperado aplicando Acesso & Decesso',
        data: regulamento?.map(c => c.y),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
      },
      {
          label: 'Esperado aplicando DR',
          data: elo?.map(c => c.y),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
  };

  var options;
  if (scale.length > 0) {
    options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 0,
                max: 600,
                ticks: {
                    callback: function (label, index, labels) {
                        var e;
                        switch (label) {
                            case 0:
                                e = 'Sem Ranking'; break;
                            case 1:
                                e = 'Iniciante'; break;
                            case 100:
                                e = 'Amador'; break;
                            case 200:
                                e = 'Intermediário'; break;
                            case 300:
                                e = 'Avançado'; break;
                            case 400:
                                e = 'Pro'; break;
                            case 500:
                                e = 'World Tour'; break;
                            // default: e = scale.find(element => label >= element.min && label <= element.max)?.label;
                        }
                        // e = scale.find(element => label >= element.min && label <= element.max)?.label;

                        return e;
                    }
                }
            }
        },
        legend: {
          labels: {
            fontSize: 25,
          },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += scale.find(element => context.parsed.y >= element.min && context.parsed.y <= element.max)?.label;
                        }
                        return label;
                    }
                }
            }
        }
      }
  }

  const fetchHistorico = async (id) => {
    await fetch(`${historicoUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setHistorico(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const fetchRegulamento = async (id) => {
    await fetch(`${regulamentoUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setRegulamento(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const fetchElo = async (id) => {
    await fetch(`${eloUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setElo(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const fetchScale = async (id) => {
    await fetch(`${scaleUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setScale(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const loadGrafico = async(id) => {
    if (!id) { return; }
    console.log(id);

    fetchHistorico(id);
    fetchRegulamento(id);
    fetchElo(id);
    fetchScale(id);
  };

  const getLabel = (piloto) => {
    if (!piloto) { return; }

    const label = piloto?.apelidos ? piloto.apelidos[piloto.apelidos.length - 1] : piloto.label;
    const apelidos = piloto?.apelidos ? ( piloto.apelidos.length > 1 ? ' (' + piloto.apelidos + ')' : '') : '';

    return label + apelidos;
  }

  return (    
    <div>
      <PublicMenu/>

      <div>
        <Autocomplete
          value={piloto}
          getOptionLabel={option => getLabel(option)}
          onChange={(event, newValue) => { loadGrafico(newValue?.id); setPiloto(newValue); }}
          options={pilotos}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Piloto" />}
        />
      </div>
      <div>
      <Line
        data={data}
        height={100}
        options={options}
      />
      </div>
    </div>
  )
}

export default Grafico