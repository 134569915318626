// ./components/LineChart.js

import React from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

const labels = ["BAV: 15 Campeonato Brasileiro", "BAV: 16 Campeonato Brasileiro", "BAV: 17 Campeonato Brasileiro", "BAV: 18 Campeonato Brasileiro", "BAV: 19 Campeonato Brasileiro", ];

const data = {
  labels: labels,
    datasets: [
        {
            label: "Grid que participou na Liga",
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [100, 200, 300, 100, 200]            
        },
    {
        label: "Nivel Real do piloto",
        backgroundColor: "rgb(0, 12, 123)",
        borderColor: "rgb(0, 12, 123)",
        data: [100, 200, 300, 400, 300],
        options: {
            scales: {
                y: {
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, ticks) {
                            return '$' + value;
                        }
                    }
                }
            }
        }
    },
  ]
};

const opt = {
    scales: {
        y: {
            ticks: {
                callback: function (label, index, labels) {
                    console.log('oi');
                    switch (label) {
                        case 0:
                            return 'Sem Ranking';
                        case 1:
                            return 'Iniciante';
                        case 100:
                            return 'Amador';
                        case 200:
                            return 'Intermediário';
                        case 300:
                            return 'Avançado';
                        case 400:
                            return 'Pro';
                        case 500:
                            return 'World Tour';
                        }
                }
            }
        },
    },
};

const LineChart = () => {
  return (
    <div>
      <Line data={data} options={opt} />
    </div>
  );
};

export default LineChart;