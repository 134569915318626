import React, { useContext, useEffect, useState } from "react";

function AddResultados() {

  const [edicoes, setEdicoes] = useState([]);
  const [estruturas, setEstruturas] = useState([]);

  // const [edicao, setEdicao] = useState();
  // const [estrutura, setEstrutura] = useState();

  useEffect(() => {
    async function getEdicoes() {
      const response = await fetch("https://rankingav.us-3.evennode.com" + "/api/campeonato/123/edicao");
      const body = await response.json();
      setEdicoes(body.map(element => ({ label: element.nome, value: element.id })));
    }
    async function getEstruturas() {
      const response = await fetch("https://rankingav.us-3.evennode.com" + "/api/campeonato/123/estrutura");
      const body = await response.json();
      setEstruturas(body.map(element => ({ label: element.nome, value: element.id })));
    }

    getEdicoes();
    getEstruturas();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const sendData = {
      'campeonato': form[0].value,
      'grid': form[1].value,
      'tabela': [
        { 'piloto': form[2].value, 'posicao': 1 },
        { 'piloto': form[3].value, 'posicao': 2 },
        { 'piloto': form[4].value, 'posicao': 3 },
        { 'piloto': form[5].value, 'posicao': 4 },
        { 'piloto': form[6].value, 'posicao': 5 },
        { 'piloto': form[7].value, 'posicao': 6 },
        { 'piloto': form[8].value, 'posicao': 7 },
        { 'piloto': form[9].value, 'posicao': 8 },
        { 'piloto': form[10].value, 'posicao': 9 },
        { 'piloto': form[11].value, 'posicao': 10 },
        { 'piloto': form[12].value, 'posicao': 11 },
        { 'piloto': form[13].value, 'posicao': 12 },
        { 'piloto': form[14].value, 'posicao': 13 },
        { 'piloto': form[15].value, 'posicao': 14 },
        { 'piloto': form[16].value, 'posicao': 15 }      
      ]
    }

    fetch("https://rankingav.us-3.evennode.com" + "/api/edicao/123/resultado", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sendData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged === true) {
          alert("Saved Successfully");
        }

        form.reset();
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>

        <div>
        {/* <select onChange={e => setEdicao(e.currentTarget.value)}> */}
        <select>
          {edicoes.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        </div>

        <div>
        {/* <select onChange={e => setEstrutura(e.currentTarget.value)}> */}
        <select>
          {estruturas.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        </div>

        <div>
        <table>
          <thead>
            <tr>
              <th>Posição</th>
              <th>Piloto</th>
            </tr>
          </thead>
          <tbody>
            <tr key="p1"><td>1</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p2"><td>2</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p3"><td>3</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p4"><td>4</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p5"><td>5</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p6"><td>6</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p7"><td>7</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p8"><td>8</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p9"><td>9</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p10"><td>10</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p11"><td>11</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p12"><td>12</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p13"><td>13</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p14"><td>14</td><td><input  type="text" name="piloto"/></td></tr>
            <tr key="p15"><td>15</td><td><input  type="text" name="piloto"/></td></tr>
          </tbody>
        </table>
        </div>

        <input type="submit" name="" id="" />
      </form>
    </div>
  );
}

export default AddResultados;
