import React, { useState, useEffect, useContext } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ResponsiveAppBar from './ResponsiveAppBar';
import PublicMenu from './PublicMenu';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const GraficoCompare = () => {
  const [pilotos, setPilotos] = useState([]);

  const [pilotoA, setPilotoA] = useState(null);
  const [pilotoB, setPilotoB] = useState(null);

  const [eloA, setEloA] = useState([])
  const [eloB, setEloB] = useState([])

  const [scale, setScale] = useState([])

  var pilotosUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto";
  var eloUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto/_id_/grafico/elo";
  var scaleUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto/_id_/grafico/scale";

  useEffect(() => {

      const fetchPilotos = async () => {
        await fetch(`${pilotosUrl}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
              if (response.ok) {
              response.json().then((json) => {
                  setPilotos(json)
              });
            }
          }).catch((error) => {
            console.log(error);
          });
      };

    fetchPilotos();
  }, [pilotosUrl])

  const champs = eloA && eloB ? 
      Math.max(eloA.length, eloB.length) :
          (eloA ? 
            eloA.length : 
                  (eloB ? eloB.length : null)
          );

  var data = {
    labels: Array.from({length: champs}, (_, i) => 'Campeonato ' + (i + 1)),
    datasets: [
        {
          label: 'DR: ' + pilotoA?.label,
          data: eloA?.map(c => c.y),
        },
        {
          label: 'DR: ' + pilotoB?.label,
          data: eloB?.map(c => c.y),
        }]
  };

  var options;
  if (scale.length > 0) {
    options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 0,
                max: 600,
                ticks: {
                    callback: function (label, index, labels) {
                        var e;
                        switch (label) {
                            case 0:
                                e = 'Sem Ranking'; break;
                            case 1:
                                e = 'Iniciante'; break;
                            case 100:
                                e = 'Amador'; break;
                            case 200:
                                e = 'Intermediário'; break;
                            case 300:
                                e = 'Avançado'; break;
                            case 400:
                                e = 'Pro'; break;
                            case 500:
                                e = 'World Tour'; break;
                            // default: e = scale.find(element => label >= element.min && label <= element.max)?.label;
                        }
                        // e = scale.find(element => label >= element.min && label <= element.max)?.label;

                        return e;
                    }
                }
            }
        },
        legend: {
          labels: {
            fontSize: 25,
          },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += scale.find(element => context.parsed.y >= element.min && context.parsed.y <= element.max)?.label;
                        }
                        return label;
                    }
                }
            }
        }
      }
  }

  const fetchElo = async (id, aOrB) => {
    await fetch(`${eloUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            aOrB === 'A' ? setEloA(json) : setEloB(json);
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const fetchScale = async (id) => {
    await fetch(`${scaleUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setScale(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const loadGrafico = async(id, aOrB) => {
    if (!id) { return; }
    console.log(id);

    fetchElo(id, aOrB);
    fetchScale(id, aOrB);
  };

  const getLabel = (piloto) => {
    if (!piloto) { return; }

    const label = piloto?.apelidos ? piloto.apelidos[piloto.apelidos.length - 1] : piloto.label;
    const apelidos = piloto?.apelidos ? ( piloto.apelidos.length > 1 ? ' (' + piloto.apelidos + ')' : '') : '';

    return label + apelidos;
  }

  return (    
    <div>
      <PublicMenu/>

      <div>
        <Autocomplete
          value={pilotoA}
          getOptionLabel={option => getLabel(option)}
          onChange={(event, newValue) => { loadGrafico(newValue?.id, 'A'); setPilotoA(newValue); }}
          options={pilotos}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Piloto A" />}
        />
        <Autocomplete
          value={pilotoB}
          getOptionLabel={option => getLabel(option)}
          onChange={(event, newValue) => { loadGrafico(newValue?.id, 'B'); setPilotoB(newValue); }}
          options={pilotos}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Piloto B" />}
        />
      </div>
      <div>
      <Line
        data={data}
        height={100}
        options={options}
      />
      </div>
    </div>
  )
}

export default GraficoCompare