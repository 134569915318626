import React, { useState, useEffect, useContext } from 'react'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ResponsiveAppBar from './ResponsiveAppBar';
import PublicMenu from './PublicMenu';

const Historico = () => {
  const [resultados, setResultados] = useState([]);
  const [pilotos, setPilotos] = useState([]);
  const [piloto, setPiloto] = useState(null);

  var historicoUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto/_id_/historico";
  var pilotosUrl = "https://rankingav.us-3.evennode.com" + "/api/piloto";

  useEffect(() => {

      const fetchPilotos = async () => {
        await fetch(`${pilotosUrl}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
              if (response.ok) {
              response.json().then((json) => {
                  setPilotos(json)
              });
            }
          }).catch((error) => {
            console.log(error);
          });
      };

    fetchPilotos();
  }, [pilotosUrl])

  console.log("resultados", resultados);
  console.log("pilotos", pilotos);

  const fetchResultados = async (id) => {
    await fetch(`${historicoUrl.replace('_id_', id)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setResultados(json)
          });
        }
      }).catch((error) => {
        console.log(error);
      });
  };

  const listResultados = async(id) => {
    if (!id) { setResultados([]); return; }
    console.log(id);

    fetchResultados(id);
  };

  const getLabel = (piloto) => {
    if (!piloto) { return; }

    const label = piloto?.apelidos ? piloto.apelidos[piloto.apelidos.length - 1] : piloto.label;
    const apelidos = piloto?.apelidos ? ( piloto.apelidos.length > 1 ? ' (' + piloto.apelidos + ')' : '') : '';

    return label + apelidos;
  }

  const lista = resultados.map(r =>
    <li>
        <p>{r.nome_liga} - {r.nome_campeonato} - {r.edicao_campeonato}</p>
        <p>Data: {r.data_campeonato}</p>
        <p>Grid: {r.categoria}</p>
        <p>Posicão final: {r.posicao_tabela}</p>
    </li>
  );

  return (
    <div>
      <PublicMenu/>

      <div>
        <Autocomplete
          value={piloto}
          getOptionLabel={option => getLabel(option)}
          onChange={(event, newValue) => { listResultados(newValue?.id); setPiloto(newValue); }}
          options={pilotos}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Piloto" />}
        />
      </div>
      <div>
        <ul>{lista}</ul>
      </div>
    </div>
  )
}

export default Historico