import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AddResultados from "./components/AddResultados.js";
import Historico from "./components/Historico.js";
import Ranking from "./components/Ranking.js";
import Grid from "./components/Grid.js";
import EditPiloto from './components/EditPiloto.js';
import GraficoCompare from './components/GraficoCompare.js';
import ResultadoCampeonato from './components/ResultadoCampeonato.js';

const router = process.env.NODE_ENV === 'development' ? createBrowserRouter([
  {
    path: "/resultados/add",
    element: <AddResultados />,
  },
  {
    path: "/campeonato/resultados",
    element: <ResultadoCampeonato />,
  },
  {
    path: "/piloto/historico",
    element: <Historico />,
  },
  {
    path: "/piloto/edit",
    element: <EditPiloto />,
  },
  {
    path: "/piloto/comparar",
    element: <GraficoCompare />,
  },
  {
    path: "/ranking",
    element: <Ranking />,
  },
  {
    path: "/grid",
    element: <Grid />,
  },
  {
    path: "/",
    element: <App />,
  },
]) : createBrowserRouter([
  {
    path: "/campeonato/resultados",
    element: <ResultadoCampeonato />,
  },
  {
    path: "/piloto/historico",
    element: <Historico />,
  },
  {
    path: "/piloto/comparar",
    element: <GraficoCompare />,
  },
  {
    path: "/ranking",
    element: <Ranking />,
  },
  {
    path: "/grid",
    element: <Grid />,
  },
  {
    path: "/",
    element: <App />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
