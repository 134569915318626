import React, { useState, useEffect, useContext } from 'react'
import ResponsiveAppBar from './ResponsiveAppBar';
import PublicMenu from './PublicMenu';

const Ranking = () => {
  const [resultados, setResultados] = useState([ ]);
  
  var rankingUrl = "https://rankingav.us-3.evennode.com" + "/api/ranking";

  useEffect(() => {

      const fetchResultados = async () => {
        await fetch(`${rankingUrl}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
              if (response.ok) {
              response.json().then((json) => {
                  setResultados(json)
              });
            }
          }).catch((error) => {
            console.log(error);
          });
      };

      fetchResultados();
  }, [rankingUrl])

  const getLabel = (piloto) => {
    if (!piloto) { return; }

    const label = piloto?.apelidos ? piloto.apelidos[piloto.apelidos.length - 1] : piloto.label;
    const apelidos = piloto?.apelidos ? ( piloto.apelidos.length > 1 ? ' (' + piloto.apelidos + ')' : '') : '';

    return label + apelidos;
  }

  const wt = (resultados.find(r => r.grid === 'S') || { 'ranking': []}).ranking.map(r =>
    <li>
      <p>DR {r.dr}: {getLabel(r)}</p>
    </li>
  );

  const pf = (resultados.find(r => r.grid === 'A+') || { 'ranking': []}).ranking.map(r =>
    <li>
      <p>DR {r.dr}: {getLabel(r)}</p>
    </li>
  );

  const av = (resultados.find(r => r.grid === 'A') || { 'ranking': []}).ranking.map(r =>
    <li>
      <p>DR {r.dr}: {getLabel(r)}</p>
    </li>
  );

  const it = (resultados.find(r => r.grid === 'B') || { 'ranking': []}).ranking.map(r =>
    <li>
      <p>DR {r.dr}: {getLabel(r)}</p>
    </li>
  );

  const am = (resultados.find(r => r.grid === 'C') || { 'ranking': []}).ranking.map(r =>
    <li>
      <p>DR {r.dr}: {getLabel(r)}</p>
    </li>
  );

  const ca = (resultados.find(r => r.grid === 'D') || { 'ranking': []}).ranking.map(r =>
    <li>
      <p>DR {r.dr}: {getLabel(r)}</p>
    </li>
  );

  const sr = (resultados.find(r => r.grid === 'N') || { 'ranking': []}).ranking.map(r =>
    <li>
      <p>DR {r.dr}: {getLabel(r)}</p>
    </li>
  );

  const ou = (resultados.find(r => r.grid === 'O') || { 'ranking': []}).ranking.map(r =>
    <li>
      <p>DR {r.dr}: {getLabel(r)}</p>
    </li>
  );

  return (
    <div>
        <PublicMenu/>

        <p>World Tour</p>
        <ul>{wt}</ul>

        <p>Profissional</p>
        <ul>{pf}</ul>

        <p>Avançado</p>
        <ul>{av}</ul>

        <p>Intermediário</p>
        <ul>{it}</ul>

        <p>Amador</p>
        <ul>{am}</ul>

        <p>Casual</p>
        <ul>{ca}</ul>

        <p>Sem Ranking</p>
        <ul>{sr}</ul>

        <p>Outros</p>
        <ul>{ou}</ul>

    </div>
  )
}

export default Ranking