import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useNavigate } from 'react-router-dom';

export default function PublicMenu() {
  const navigate = useNavigate();

  return (
    <div>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button variant="contained" {...bindTrigger(popupState)}>
              Gráficos
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={(e) => {  bindTrigger(popupState).onClick(e); navigate('/')}}>Piloto</MenuItem>
              <MenuItem onClick={(e) => {  bindTrigger(popupState).onClick(e); navigate('/piloto/comparar')}}>Comparar Pilotos</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>

      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button variant="contained" {...bindTrigger(popupState)}>
              Histórico
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={(e) => {  bindTrigger(popupState).onClick(e); navigate('/piloto/historico')}}>Piloto</MenuItem>
              <MenuItem onClick={(e) => {  bindTrigger(popupState).onClick(e); navigate('/campeonato/resultados')}}>Campeonato</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>

      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button variant="contained" {...bindTrigger(popupState)}>
              Análises
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={(e) => {  bindTrigger(popupState).onClick(e); navigate('/grid')}}>Grid</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>

      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <Button variant="contained" {...bindTrigger(popupState)}>
              Ranking
            </Button>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={(e) => {  bindTrigger(popupState).onClick(e); navigate('/ranking')}}>Geral</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </div>
  );
}